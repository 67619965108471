import * as constants from "./constants";

export default class RequestUtils {

    static uriResolver (modalidade) {
        return modalidade === constants.MODALIDADE_ALUGUEL ? constants.APICadimo5Url : constants.APIBaseUrl;
    }

    static tokenResolver (modalidade) {
        return modalidade === constants.MODALIDADE_ALUGUEL ? 
        [
            {'key': 'Authorization', 'value': 'Basic ' + constants.TokenCadimo5Api},
            {'key': 'Token-Conta', 'value': constants.TokenConta},
        ] : 
        [{'key': 'Authorization', 'value': constants.TokenAPI}];
    }

    static methodResolver (method, modalidade) {
        switch (method) {
            case 'busca':
                return constants.METODOS[modalidade]['busca'];
            case 'tipos':
                return constants.METODOS[modalidade]['tipos'];
            case 'destaques':
                return constants.METODOS[modalidade]['destaques'];
            case 'destaquesEspeciais':
                return constants.METODOS[modalidade]['destaquesEspeciais'];
            case 'detalhes':
                return constants.METODOS[modalidade]['detalhes'];
            case 'caracteristicas':
                return constants.METODOS[modalidade]['caracteristicas'];
            case 'caracteristicasCondominio':
                return constants.METODOS[modalidade]['caracteristicasCondominio'];
            case 'buscaEndereco':
                return constants.METODOS[modalidade]['buscaEndereco'];
            
            default:
                return 'busca';
        }
    }

};

