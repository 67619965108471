export const APIBaseUrl = 'https://api.dmvimoveis.com.br/v1/venda/';
export const APICadimo5Url = 'https://cadimo.imobsoft.com.br:8052/';
export const TokenAPI = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6ImFwaSBkbXYgaW1vdmVpcyIsImlhdCI6MTUxNjIzOTAyMn0.yc7GzykL7A1cY7cKK7TePxoXYo9xJFwei5sFagDTU_w';
export const TokenCadimo5Api = btoa('dmv:wuwIa6InCyF7hKeS');
export const TokenConta = 'wuwIa6InCyF7hKeS';
export const MODALIDADE_ALUGUEL = 'aluguel';
export const MODALIDADE_VENDA = 'venda';
export const METODOS = {
    "aluguel" : {
        'busca': 'BuscaImoveis',
        'tipos': 'CarregaTipos',
        'destaques': 'CarregaDestaques',
        'detalhes': 'CarregaDetalhes/',
        'destaquesEspeciais': 'CarregaDestaques',
        'caracteristicas': 'CarregaCaracteristicas/',
        'caracteristicasCondominio': 'CarregaCaracteristicasCondominio/',
        'buscaEndereco': 'CarregaBairrosCidades',
        'imoveisProximos': 'CarregaVizinhos/6/'
    },
    "venda" : {
        'busca': 'imoveis',
        'tipos': 'tiposImoveisOptions',
        'destaques': 'destaques',
        'detalhes': 'detalhes?codigo=',
        'destaquesEspeciais': 'especialSales',
        'caracteristicas': 'caracteristicasOptions?tipo=',
        'caracteristicasCondominio': 'caracteristicasCondominioOptions?tipo=',
        'buscaEndereco': 'buscaEndereco?palavra='
    }
};