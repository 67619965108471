import React from 'react';
import { Link } from 'react-router-dom';
import { FiMapPin } from 'react-icons/fi';
import EspecialLabel from '../../EspecialLabel';
import utils from '../../../helpers/utils';
import Favorites from '../../../helpers/Favorites';
import Comparatives from '../../../helpers/Comparatives';

import './styles.css';

export default function ImovelCard({data, icon}) {
    const _favorites = new Favorites();
    const _comparativesProperties = new Comparatives();


    const getSlug = (tipo, quartos, suites, bairro, cidade, estado) => {
        let dormitorios_total = quartos + suites;
        let slug = '';

        if (dormitorios_total === 1) {
            slug = `${tipo}-${dormitorios_total}-dormitorio-no-bairro-${bairro}-em-${cidade}-${estado}`;
        } else if (dormitorios_total > 1) {
            slug = `${tipo}-${dormitorios_total}-dormitorios-no-bairro-${bairro}-em-${cidade}-${estado}`;
        } else {
            slug = `${tipo}-no-bairro-${bairro}-${cidade}-${estado}`;
        }

        return utils.transliterate(slug);
    }

    const link = `/detalhes/${data.modalidade.toLowerCase()}/${data.id}/${getSlug(data.tipo, data.quartos, data.suites, data.bairro, data.cidade, data.estado)}`;

    return (
        
        <div id={`card-content-${data.id}`} className="imovel-card">

            {data.label_destaque !== '' ?
                <EspecialLabel 
                    label={data.label_destaque}
                    color={data.modalidade === 'venda' && data.label_destaque ? 'orange':'green'}
                />
            : ''}

            <div className="box-buttons">
                <i 
                    id={`icon_${data.id}`} 
                    className={_favorites.getIcons(data.id, icon)} 
                    onClick={() => _favorites.update(data)}
                />
      
                <i 
                    id={`icon-comparatives-${data.id}`} 
                    className={_comparativesProperties.getIcons(data.id)}
                    onClick={() => _comparativesProperties.update(data)}
                />
            </div>

            <div className="box-image">
                <Link to={link}>
                    <img src={data.modalidade.toLowerCase() === 'aluguel' ? data.imagens[0].imagem : data.foto} alt={data.titulo}/>
                </Link>                
            </div>

            <div className="wrapper">
                <h2>
                    <Link to={link}>
                    {data.titulo}
                    </Link>
                </h2>                

                <h3>
                    <span>R$ {data.valor ? data.valor.replace(',00','') : ''}</span>
                    {data.area_total ? data.area_total.toString().replace(',00','') : ''}m² de área
                </h3>

                <ul>                
                    <li>
                        <ul>
                            {data.suites > 1 && data.suites !== data.quartos && data.quartos !== 0 &&
                                <li>
                                    <i className="fas fa-bed"/>
                                    {data.quartos} dormitórios,<br/> sendo {data.suites} suítes
                                </li>
                            }
                            {data.suites === 1 && data.suites !== data.quartos && data.quartos !== 0 &&
                                <li>
                                    <i className="fas fa-bed"/>
                                    {data.quartos} dormitórios,<br/> sendo {data.suites} suíte
                                </li>
                            }
                            {data.suites === data.quartos && data.quartos !== 0 &&
                                <li>
                                    <i className="fas fa-bed"/>
                                    {data.suites} suítes
                                </li>
                            }
                            {data.quartos === 0 && data.suites > 0 &&
                                <li>
                                    <i className="fas fa-bed"/>
                                    {data.suites} suítes
                                </li>
                            }
                            {data.quartos === 0 && data.suites === 0 &&
                                <li>
                                    <i className="fas fa-bed"/>
                                    {data.suites} dormitórios
                                </li>
                            }
                        </ul>
                    </li>
                    <li>
                        <i className="fas fa-bath"/> 
                        {data.banheiros} wc's
                    </li>
                    <li>
                        <i className="fas fa-car"/>
                        {data.vagas} vagas
                    </li>
                </ul>                                  
            </div>
            <div className="footer">
                <address>
                    <FiMapPin />
                    {data.bairro}, {data.cidade} - {data.estado}
                </address>
                <Link to={link}>Detalhes</Link>
            </div>
        </div>
    );
}