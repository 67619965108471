import axios from 'axios';
import RequestUtils from "../config/requestUtils";

export default class ApiService {
    static create(modalidade = 'aluguel') {
        const data = RequestUtils.tokenResolver(modalidade);
        const uri = RequestUtils.uriResolver(modalidade);
        const headersObj = {
            'Content-Type' : 'application/json',
            'Cache-Control': 'no-cache',
        };

        data.forEach(header => {
            headersObj[header['key']] = header.value;
        });

        return axios.create({
            baseURL: uri,
            headers: headersObj
        });
    }
}