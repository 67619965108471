import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiChevronsRight } from 'react-icons/fi';

//import api from '../../../services/api';

import listPopularProperties from './listPopularProperties';

import empreendimento from '../../../assets/images/bg-card-01.jpg';
import venda from '../../../assets/images/bg-card-03.jpg';
import aluguel from '../../../assets/images/bg-card-02.jpg';

import './styles.css';

export default function QuickSearch() {
    const [totaRent, setTotalRent] = useState(0);
    const [totalSale, setTotalSale] = useState({});

    const getTotalEspecial = async() => {
       /*  await api.get(`/aluguel/totalEspecialRents`).then(
            response => { setTotalRent(response.data.total); }
        );

        await api.get(`/venda/totalEspecialSales`).then(
            response => { setTotalSale(response.data.imoveis); }
        ); */

        setTotalSale({
            'construcao': 56,
            'novos': 18
        });
        setTotalRent("37");
    }

    useEffect(()=>{
        getTotalEspecial()
    },[]);

    return (
        <section id="popular-properties">            

            <div className="container">
                <div className="column">
                    <h2>Vitrine de imóveis em <strong>destaque</strong></h2>
                    <ul className="cards-popular-properties">
                        <li>
                            <img src={empreendimento} alt="Empreendimentos"/>
                            <Link to="/venda/categoria/construcao">
                                <h3>Veja nossa lista de <span>lançamentos</span></h3>   
                                <h6>{ totalSale.construcao } imóveis</h6>
                            </Link>
                        </li>
                        <li>
                            <img src={venda} alt="Destaques de venda"/>
                            <Link to="/venda/categoria/novo">
                                <h3>Veja nossos <br/> imoveis novos <br/> <span>prontos para morar</span></h3>
                                <h6>{ totalSale.novos } imóveis</h6>
                            </Link>
                        </li>
                        <li>
                            <img src={aluguel} alt="Destaques de aluguel"/>
                            <Link to="/aluguel/categoria/alto-padrao">
                                <h3>Veja nossos<br/> destaque de <span>aluguel</span></h3>
                                <h6>{ totaRent } imóveis</h6>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="column">
                    <h2>Imóveis mais <strong>populares</strong></h2>
                    <ul className="list-popular-properties">

                        {listPopularProperties.map((item, index) => (
                            <li key={index}>
                            <Link to={item.to}>
                                <FiChevronsRight/> {item.label}
                            </Link>
                        </li>
                        ))}
                        
                    </ul>
                </div>
            </div>
            
        </section>
    );
}