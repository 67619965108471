import React, { useRef, useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import ContentHeadline from '../../../components/ContentHeadline';
import EspecialCard from '../../../components/Cards/EspecialCard';
import TitleLoader from '../../../components/TitleLoader';
import * as constants from '../../../config/constants';

import ApiService from '../../../services/api';

import './styles.css';

export default function EspecialSale() {
    const [sale, setSale] = useState([]);
    
    const options = {
        margin: 10,
        responsive: {0: {items: 1}, 768: {items: 2}, 990: {items: 3}}
    };

    const carousel = useRef(null);

    useEffect(() => {
        const api = ApiService.create(constants.MODALIDADE_VENDA);
        const getEspecialSales =  async() => {     
            await api.get('especialSales').then(
                response => { setSale(response.data.imoveis); }
            );
        }

        getEspecialSales();
    },[]);

    return (
        <section id='especial-sale'>
            <ContentHeadline 
                title="Imóveis em destaque para venda"
                text="Conheça as maiores oportunidades que a DMV oferece a você"
            />

            <div className="carousel">
                <div className="container">

                {sale.length > 0 ?
                    <>
                        <OwlCarousel ref={carousel} options={options}>
                            {sale.map((imovel, index) => (
                                <div key={index} className="item">
                                    <EspecialCard data={imovel} icon="heart"/>
                                </div>
                            ))}
                        </OwlCarousel>                        

                        <div className="carousel-nav">
                            <span onClick={() => carousel.current.prev()}><i className="fas fa-angle-double-left"/></span>
                            <span onClick={() => carousel.current.next()}><i className="fas fa-angle-double-right"/></span>
                        </div>
                    </>
                : 
                    <span className="loader"><TitleLoader /></span>                    
                }
                </div>
            </div>
        </section>
    );
}