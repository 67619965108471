import React, { Component } from 'react';
import {Form, Button} from "reactstrap";
import './contato.css';
import Services from './Services';
import LogoZap from '../../../assets/images/icons/whatsapp.png'

class Contato extends Component {

    constructor(props){
        super(props);
        this._services = new Services(); 
    }    

    openForm = () =>{       
        const element = document.querySelector('#content-fluid-whatsapp');
        element.classList.add('open-form');
    };

    closeForm = () =>{
        const element = document.querySelector('#content-fluid-whatsapp');
        element.classList.remove('open-form');
    }



    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        return (
            <div className="fluid-whatsapp" id="content-fluid-whatsapp">                
                <img src={LogoZap} alt="Logomarca DMV Imóveis" onClick={this.openForm} width="100%" height="auto" />
                <div className='content-form-fluid'>
                    <i className="fa fa-times" onClick={this.closeForm}></i>
                    <Form name="InteresseImovelWhats">
                        <Button type="submit" className="btn botao"><i className="fa fa-whatsapp"/><a href="https://api.whatsapp.com/send?phone=5585981048253&text=Ol%C3%A1,%20tenho%20interesse%20em%20um%20im%C3%B3vel%20da%20DMV%20Im%C3%B3veis" target="_blank" rel="noreferrer"> Dep. de Venda</a></Button>                    
                        <Button type="submit" className="btn botao"><i className="fa fa-whatsapp"/><a href="https://api.whatsapp.com/send?phone=5585982040055&text=Ol%C3%A1,%20tenho%20interesse%20em%20um%20im%C3%B3vel%20da%20DMV%20Im%C3%B3veis" target="_blank" rel="noreferrer"> Dep. de Locação</a></Button>
                    </Form>
                </div>
            </div>
        );
    }
}

export default Contato;